import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const ListItemWithText = ({ primary }) => {
  return (
    <ListItem>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

const RoadMap = () => {
  return (
    <List>
      <ListItemWithText primary="Implement backend to save user profiles" />
      <ListItemWithText primary="Implement backend to save user statistics" />
      <ListItemWithText primary="adsense to support hosting services" />
      <ListItemWithText primary="premium features" />
    </List>
  );
};

export default RoadMap;