import { db } from './firebaseInit';
import palettes from "../themes/palettes.js";
import fonts from "../utils/fonts.js";
import { testLengths, wordListSizes, languages } from '../utils/defaultValues.js';

import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  writeBatch
} from 'firebase/firestore';
import { builtInProfiles } from '../utils/builtInProfiles'

const paletteNames = Object.keys(palettes);
const fontNames = Object.keys(fonts);

//CREATE USER
export const createUserDocument = async (user) => {
  try {
    const batch = writeBatch(db);
    
    // Create user doc
    const userRef = doc(db, 'users', user.uid);
    batch.set(userRef, {
      username: user.displayName,
      email: user.email,
      created_at: serverTimestamp(),
      last_login: serverTimestamp()
    });

    // Create settings doc 
    const settingsRef = doc(db, 'user_settings', user.uid);
    batch.set(settingsRef, {
      subscription_status: 'free',
      optional_features: {},
      palette: paletteNames[0],
      font: fontNames[0],
      hasSeenTour: false
    });

    // Commit both operations atomically
    await batch.commit();

    // Initialize additional user data
    await Promise.all([
      addBuiltInProfiles(user.uid),
      createEmptyPersonalBests(user.uid)
    ]);

  } catch (error) {
    console.error('Error creating user document:', error);
    throw error;
  }
};

export const checkUserExists = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    return userDoc.exists();
  } catch (error) {
    console.error('Error checking user exists:', error);
    throw error;
  }
};

//USER SETTINGS OPERATIONS
export const getUserSettings = async (userId) => {
  try {
    const settingsRef = doc(db, 'user_settings', userId);
    const settingsDoc = await getDoc(settingsRef);
    return settingsDoc.exists() ? settingsDoc.data() : {};
  } catch (error) {
    console.error('Error getting user settings:', error);
    throw error;
  }
};

export const updateUserPalette = async (userId, paletteName) => {
  try {
    const settingsRef = doc(db, 'user_settings', userId);
    await updateDoc(settingsRef, {
      palette: paletteName
    });
  } catch (error) {
    console.error('Error updating user palette:', error);
    throw error;
  }
};

export const updateUserFont = async (userId, fontName) => {
  try {
    const settingsRef = doc(db, 'user_settings', userId);
    await updateDoc(settingsRef, {
      font: fontName
    });
  } catch (error) {
    console.error('Error updating user font:', error);
    throw error;
  }
};

export const updateUserTourStatus = async (userId, hasSeenTour) => {
  try {
    const settingsRef = doc(db, 'user_settings', userId);
    await updateDoc(settingsRef, {
      hasSeenTour: hasSeenTour
    });
  } catch (error) {
    console.error('Error updating user tour status:', error);
    throw error;
  }
};


// PROFILE oPERATIONS
// Add all the built-in profiles to the user's profile collection. Make sure
// to check if the profile already exists before adding it.
export const addBuiltInProfiles = async (userId) => {
  try {
    const profilesRef = collection(db, 'profiles', userId, 'user_profiles');
    const querySnapshot = await getDocs(profilesRef);
    const existingProfiles = querySnapshot.docs.map(doc => doc.id);
    const builtInProfileNames = Object.keys(builtInProfiles);

    // Use Promise.all to wait for all profile additions to complete
    await Promise.all(
      builtInProfileNames.map(async (profileName) => {
        if (!existingProfiles.includes(profileName)) {
          await addProfile(userId, {
            name: profileName,
            include: builtInProfiles[profileName].include,
            exclude: builtInProfiles[profileName].exclude
          });
        }
      })
    );
  } catch (error) {
    console.error('Error adding built-in profiles:', error);
    throw error;
  }
};

//add a profile to the user's profile collection
export const addProfile = async (userId, profile) => {
  console.log("ADDPROFILE")
  try {
    if (!profile.name) {
      throw new Error('Profile must have a name');
    }
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profile.name);
    await setDoc(profileRef, {
      include: profile.include || [],
      exclude: profile.exclude || []
    });
  } catch (error) {
    console.error('Error adding profile:', error);
    throw error;
  }
};

//delete a profile from the user's profile collection
export const deleteProfile = async (userId, profileName) => {
  console.log("DELETEPROFILE")
  console.log("userId", userId)
  console.log("profileName", profileName)
  try {
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profileName);
    await deleteDoc(profileRef);
  } catch (error) {
    console.error('Error deleting profile:', error);
    throw error;
  }
};

//modify a profile in the user's profile collection
export const modifyProfile = async (userId, profile) => {
  console.log("MODIFYPROFILE")
  try {
    if (!profile.name) {
      throw new Error('Profile must have a name');
    }
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profile.name);
    await updateDoc(profileRef, {
      include: profile.include || [],
      exclude: profile.exclude || []
    });
  } catch (error) {
    console.error('Error modifying profile:', error);
    throw error;
  }
};

//get all the profiles for a user - returns an array of profiles
export const getProfiles = async (userId) => {
  try {
    const profilesRef = collection(db, 'profiles', userId, 'user_profiles');
    const querySnapshot = await getDocs(profilesRef);
    const profiles = [];
    querySnapshot.forEach((doc) => {
      profiles.push({ id: doc.id, ...doc.data() });
    });
    return profiles;
  } catch (error) {
    console.error('Error getting profiles:', error);
    throw error;
  }
};

export const getProfile = async (userId, profileName) => {
  console.log("GETPROFILE")
  if (!userId) {
    throw new Error('userId is required');
  }
  if (!profileName) {
    throw new Error('profileName is required');
  }
  try {
    const profilePath = ['profiles', userId, 'user_profiles', profileName];
    const profileRef = doc(db, ...profilePath);
    const profileDoc = await getDoc(profileRef);
    return profileDoc.exists() ? profileDoc.data() : {};
  } catch (error) {
    console.error('Error getting profile:', error);
    console.error('Error details:', {
      name: error.name,
      message: error.message,
      stack: error.stack
    });
    throw error;
  }
};

export const createProfile = async (userId, profileData) => {
  try {
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profileData.name);
    await setDoc(profileRef, {
      name: profileData.name,
      active: true,
      settings: {
        included_patterns: profileData.settings.included_patterns || [],
        excluded_patterns: profileData.settings.excluded_patterns || [],
        // Add other profile settings here
      }
    });
  } catch (error) {
    console.error('Error creating profile:', error);
    throw error;
  }
};


// PERSONAL BESTS OPERATIONS
export const createEmptyPersonalBests = async (userId) => {
  try {
    const personalBestRef = doc(db, 'personal_bests', userId);
    const personalBestDoc = await getDoc(personalBestRef);

    if (!personalBestDoc.exists()) {
      const emptyData = {};
      languages.forEach(language => {
        emptyData[language] = {};
        wordListSizes.forEach(wordList => {
          emptyData[language][wordList] = {};
          testLengths.forEach(testLength => {
            emptyData[language][wordList][testLength] = {
              value: 0,
              achieved_at: null
            };
          });
        });
      });

      await setDoc(personalBestRef, emptyData);
    }
  } catch (error) {
    console.error('Error creating empty personal bests:', error);
    throw error;
  }
}

export const createEmptyPersonalBest = async (userId, wpm, language, wordList, testLength) => {
  try {
    const personalBestRef = doc(db, 'personal_bests', userId);
    await setDoc(personalBestRef, {
      [language]: {
        [wordList]: {
          [testLength]: {
            value: wpm,
            achieved_at: serverTimestamp()
          }
        }
      }
    });
  } catch (error) {
    console.error('Error creating personal best:', error);
    throw error;
  }
}

//update all pbs as 0
export const resetPersonalBests = async (userId) => {
  try {
    const personalBestRef = doc(db, 'personal_bests', userId);
    const personalBestDoc = await getDoc(personalBestRef);

    if (personalBestDoc.exists()) {
      const emptyData = {};
      languages.forEach(language => {
        emptyData[language] = {};
        wordListSizes.forEach(wordList => {
          emptyData[language][wordList] = {};
          testLengths.forEach(testLength => {
            emptyData[language][wordList][testLength] = {
              value: 0,
              achieved_at: null
            };
          });
        });
      });

      await setDoc(personalBestRef, emptyData);
    }
  } catch (error) {
    console.error('Error creating empty personal bests:', error);
    throw error;
  }
}

// Personal Bests Operations
export const updatePersonalBest = async (userId, languageId, wordListId, testLengthId, value) => {
  try {
    const bestRef = doc(db, 'personal_bests', userId);
    const bestDoc = await getDoc(bestRef);

    if (!bestDoc.exists()) {
      // Create new document with nested structure
      await setDoc(bestRef, {
        [languageId]: {
          [wordListId]: {
            [testLengthId]: {
              value,
              achieved_at: serverTimestamp()
            }
          }
        }
      });
    } else {
      // Update existing document
      const currentData = bestDoc.data();
      const currentBest = currentData?.[languageId]?.[wordListId]?.[testLengthId]?.value || 0;

      if (value > currentBest) {
        await updateDoc(bestRef, {
          [`${languageId}.${wordListId}.${testLengthId}`]: {
            value,
            achieved_at: serverTimestamp()
          }
        });
      }
    }
  } catch (error) {
    console.error('Error updating personal best:', error);
    throw error;
  }
};

export const getPersonalBests = async (userId) => {
  try {
    const bestRef = doc(db, 'personal_bests', userId);
    const bestDoc = await getDoc(bestRef);
    return bestDoc.exists() ? bestDoc.data() : {};
  } catch (error) {
    console.error('Error getting personal bests:', error);
    throw error;
  }
};