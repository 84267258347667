
const palettes = {

  canaryYellow: {
    palette: {
      primary: { main: '#FBC02D' },
      secondary: { main: '#ffdc2c' },
      background: { default: '#f5e873', paper: '#FFF9C4', },
      text: { primary: '#212121', secondary: '#212121' },
      divider: '#212121',
    },
  },

  neonNoir: {
    palette: {
      mode: 'dark',
      primary: { main: '#272647', /*light: '#33FFB1'*/ },
      secondary: { main: '#2e429e' },
      background: { default: '#0A0F14', paper: '#1C2833' },
      text: { primary: '#e1b232', secondary: '#ce93d8' },
      divider: '#000000',
    },
  },

  nightEmber: {
    palette: {
      mode: 'dark',
      primary: { main: '#4B0000', light: '#4B0000' }, // Dark red
      secondary: { main: '#A0522D' }, // Sienna (reddish-brown)
      background: { default: '#1A0000', paper: '#2D0000' }, // Very dark red, almost black
      text: { primary: '#FFA07A', secondary: '#CD853F' }, // Light salmon and Peru (light orange-brown)
      divider: '#8B0000', // Slightly lighter than the background
      action: {
        disabledBackground: '#406019', // 12% opaque version of primary.main
      },
    },
  },

  veryBlack: {
    palette: {
      mode: 'dark',
      primary: { main: '#000000', light: '#000000' }, // Bright green, lighter green
      secondary: { main: '#ffffff' }, // Dark green
      background: { default: '#000000', paper: '#000000' }, // Light honeydew green and slightly darker green for paper
      text: { primary: '#ffffff', secondary: '#ffffff' }, // Dark green and Forest green for secondary
      divider: '#ffffff', // Lime green
      action: {
        disabledBackground: '#bb9900', // 12% opaque version of primary.main
      },
      success: {
        main: '#ffd000', // Example color for success (bright green)
        contrastText: '#000000', // Text color on success
      },
    },
  },

  VSCode: {
    palette: {
      mode: 'dark',
      primary: { main: '#3B3B3C', light: '#323233' },
      secondary: { main: '#9D9D9D' },
      background: { default: '#1D2225', paper: '#1D2225', },
      text: { primary: '#D4D4D4', secondary: '#FFCA28' },
      divider: '#6B6B6C',
    },
  },

  nightGlacer: {
    palette: {
      mode: 'dark',
      primary: { main: '#1a2d47', },
      secondary: { main: '#1b7688' },
      background: { default: '#0e141a', paper: '#243d4e' },
      text: { primary: '#b0f6f9', secondary: '#91d2d8' },
      divider: '#1c3a4e',
    },
  },

  nvidiaGlow: {
    palette: {
      mode: 'dark',
      primary: { main: '#76B900', light: '#76B900' },
      secondary: { main: '#76B900' },
      background: { default: '#0A0A0A', paper: '#1E1E1E' },
      text: { primary: '#FFFFFF', secondary: '#A8E6CF' },
      divider: '#2C2C2C',
      success: { main: '#76B900', dark: '#76B900', light: '#76B900' }, // Added success color
    },
  },

  plainPeriwinkle: {
    palette: {
      primary: { main: '#C5CAE9', light: '#C5CAE9' },
      secondary: { main: '#7e84ac' },
      background: { default: '#C5CAE9', paper: '#C5CAE9' },
      text: { primary: '#7f5b06', secondary: '#7f5b06' },
      divider: '#000000',
    },
  },

  coldSteel: {
    palette: {
      primary: { main: '#6f8691', },
      secondary: { main: '#90A4AE' },
      background: { default: '#CFD8DC', paper: '#ECEFF1' },
      text: { primary: '#263238', secondary: '#263238' },
      divider: '#263238',
    },
  },

  ripeMango: {
    palette: {
      primary: { main: '#FF9800' },
      secondary: { main: '#FFB74D' },
      background: { default: '#FFE0B2', paper: '#FFF3E0' },
      text: { primary: '#3E2723', secondary: '#6D4C41' },
      divider: '#3E2723',
    },
  },

  lavenderDream: {
    palette: {
      primary: { main: '#9B59B6' },
      secondary: { main: '#bd79a1' },
      background: { default: '#E8DAEF', paper: '#F4ECF7' },
      text: { primary: '#0e141a', secondary: '#34495E' },
      divider: '#BB8FCE',
    },
  },

  cocoaComfort: {
    palette: {
      mode: 'dark',
      primary: { main: '#a3580d', /*light: '#846441'*/ },
      secondary: { main: '#372007' },
      background: { default: '#a1784e', paper: '#d7ae5e' },
      text: { primary: '#1e0e05', secondary: '#1e0e05' },
      divider: '#1e0e05',
    },

  },


  // volcanicNight: {
  //   palette: {
  //     mode: 'dark',
  //     primary: { main: '#FF6B35' }, // Vibrant orange
  //     secondary: { main: '#A9A9A9' }, // Medium grey
  //     background: { default: '#1A1A1A', paper: '#2C2C2C' }, // Dark grey to black
  //     text: { primary: '#FFFFFF', secondary: '#FFA07A' }, // White and light orange
  //     divider: '#4A4A4A', // Darker grey
  //   },
  // },

};

export default palettes;