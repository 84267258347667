import React, { createContext, useState, useEffect, useRef } from "react";
import { onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { auth } from "../utils/firebaseInit.js";
import palettes from "../themes/palettes.js";
import fonts from "../utils/fonts.js";
// import axios from "axios";
import config from "../config";
import { updateUserPalette, updateUserFont, getUserSettings } from "../utils/firestoreUtils";

const paletteNames = Object.keys(palettes);
const fontNames = fonts.map((font) => font.font);

// Function to validate the palette name
const validatePaletteName = (paletteName) => {
  return paletteNames.includes(paletteName) ? paletteName : paletteNames[0];
};

const validateFontName = (fontName) => {
  return fontNames.includes(fontName) ? fontName : fontNames[0];
}

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const [currUser, setCurrUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [displayedPalette, setDisplayedPalette] = useState(paletteNames[0]);
  const [displayedFont, setDisplayedFont] = useState("Courier Prime");
  const clickedPalette = useRef(displayedPalette);
  const clickedFont = useRef(displayedFont);
  const [userSettingsLoaded, setUserSettingsLoaded] = useState(false);

  const [isWebsiteReady, setIsWebsiteReady] = useState(false);

  useEffect(() => {
    const ready = !isUserLoading && (userSettingsLoaded || !currUser);
  
    if (ready) {
      const timeoutId = setTimeout(() => {
        setIsWebsiteReady(true);
      }, 500);
      return () => clearTimeout(timeoutId);
    } else {
      setIsWebsiteReady(false);
    }
  }, [isUserLoading, userSettingsLoaded, currUser]);

  useEffect(() => {
    console.log("Palette changed via user:", clickedPalette.current);
    if (!isUserLoading && currUser && currUser.uid) {
      updateUserPalette(currUser.uid, clickedPalette.current);
    }
    // localStorage.setItem("palette", displayedPalette);
  }, [clickedPalette.current]);

  //font
  useEffect(() => {
    console.log("Font changed via user:", clickedFont.current);
    if (!isUserLoading && currUser && currUser.uid) {
      updateUserFont(currUser.uid, clickedFont.current);
    }
    // localStorage.setItem("font", displayedFont);
  }, [clickedFont.current]);

  useEffect(() => {
    if (!isUserLoading) {
      let savedPalette = "";
      let savedFont = "";
      if (currUser && currUser.uid) {
        getUserSettings(currUser.uid).then((settings) => {
          savedPalette = settings.palette;
          setDisplayedPalette(validatePaletteName(savedPalette));
          savedFont = settings.font;
          setDisplayedFont(validateFontName(savedFont));
          setUserSettingsLoaded(true);
        })
          .catch((error) => {
            console.error("Error getting user settings:", error);
            setDisplayedPalette(validatePaletteName(paletteNames[0]));
            setDisplayedFont(validateFontName("Courier Prime"));
            setUserSettingsLoaded(true);
          });
      } else {
        // savedPalette = localStorage.getItem("palette");
        setDisplayedPalette(validatePaletteName(paletteNames[0]));
        // savedFont = localStorage.getItem("font");
        setDisplayedFont(validateFontName("Courier Prime"));
      }
    }
  }, [isUserLoading, currUser]);

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User signed in:", user.uid);
        setCurrUser(user);
      } else {
        console.log("User signed out");
        setCurrUser(null);
      }
      setIsUserLoading(false);
    });

    // Handle redirect result from popup fallback
    getRedirectResult(auth).catch((error) => {
      console.error("Redirect result error:", error.code, error.message);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  const contextValue = {
    displayedPalette,
    setDisplayedPalette,
    clickedPalette,
    clickedFont,
    currUser,
    setCurrUser,
    isUserLoading,
    isFocused,
    setIsFocused,
    setIsUserLoading,
    displayedFont,
    setDisplayedFont,
    isSidebarOpen,
    setIsSidebarOpen,
    userSettingsLoaded,
    setUserSettingsLoaded,
    isWebsiteReady,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
