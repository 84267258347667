import React from 'react';
import { useContext } from 'react';
import { Typography, Button, Tooltip } from '@mui/material';
import { MdRestore } from "react-icons/md";
import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext';
import {
  defaultSlowPatternTemplates, defaultInaccuratePatternTemplates,
  defaultDesiredWeaknessHistory, defaultDesiredRefreshInterval,
  defaultInaccuratePatternsCount, defaultSlowPatternsCount,
  defaultDesiredRandomness, defaultRandomDuration, defaultDesiredWordsHistory,
  defaultLetterLimit,
} from '../utils/defaultValues.js';
import { addBuiltInProfiles } from '../utils/firestoreUtils';
import { builtInProfiles } from '../utils/builtInProfiles';

const RestoreDefaultSettingsButton = () => {
  const { setSlowPatternTemplates,
    setInaccuratePatternTemplates, setLoadedProfile, setDesiredWeaknessHistory,
    setDesiredRefreshInterval, setInaccuratePatternsNum, setSlowPatternsNum,
    setRandomDuration, setDesiredWordsHistory} = useContext(MainContentContext);
  const { setDesiredRandomness, clearHistoryManager, setLetterLimit } = useContext(SuperMainContentContext);
  const {currUser} = useContext(AppContext);
  const {profileOptions, setProfileOptions} = useContext(MainContentContext);

  const restoreDefaultSettings = () => {
    setSlowPatternTemplates(defaultSlowPatternTemplates);
    setInaccuratePatternTemplates(defaultInaccuratePatternTemplates);
    setLoadedProfile('');
    setDesiredWeaknessHistory(defaultDesiredWeaknessHistory);
    setDesiredWordsHistory(defaultDesiredWordsHistory)
    setDesiredRefreshInterval(defaultDesiredRefreshInterval);
    setInaccuratePatternsNum(defaultInaccuratePatternsCount);
    setSlowPatternsNum(defaultSlowPatternsCount);
    setDesiredRandomness(defaultDesiredRandomness);
    setRandomDuration(defaultRandomDuration);
    setLetterLimit(defaultLetterLimit);
    clearHistoryManager();
    addBuiltInProfiles(currUser.uid);
//set current profiles + built-in profiles but avoid duplicates and sort them by name
    setProfileOptions([...new Set([...Object.keys(builtInProfiles), ...profileOptions])].sort());
  }

  return (
    <Tooltip title="This only restores the settings we deemed worth restoring.">
      <Button
        variant="contained"
        startIcon={<MdRestore />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '6px 16px',
          width: '100%',
          // backgroundColor: theme.palette.background.paper,
          // borderColor: theme.palette.divider,
          // color: theme.palette.text.primary,
          // '&:hover': {
          //   backgroundColor: theme.palette.background.default,
          //   borderColor: theme.palette.divider,
          // },
        }}
        onClick={restoreDefaultSettings}
      >
        <Typography>Restore Default Settings</Typography>
      </Button>
    </Tooltip>
  );
}

export { RestoreDefaultSettingsButton };