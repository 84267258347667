import React from 'react';
import SidebarMenu from './SidebarMenu';
import { Footer } from './Footer';
import { Box } from '@mui/material';


const Layout = ({ children }) => {
  return (
    <>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>
        {/* <Header /> */}
        <SidebarMenu />
        {/* <Box sx={{ flexGrow: 1, width: '90%', padding: '0 32px', margin: '0 auto' }}> */}

        <Box sx={{ width: '90%', padding: '0 32px', flex: 1 }}>
          {children}
        </Box>
        {/* <Footer /> */}
        <Footer />
      </Box>
    </>
  );
};


export { Layout };