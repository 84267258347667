import React, { useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { TTDropdownMenu } from './TTDropdownMenu';
import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext';
import {
  wordListSizes, testLengths, languages, defaultTestLength,
  defaultWordList, defaultLanguage
} from '../utils/defaultValues';

function TypingTestOptions(props) {

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const { setIsFocused } = useContext(AppContext);

  const {
    clearPatternBoxes
  } = useContext(MainContentContext);

  const {
    selectedLanguage,
    setSelectedLanguage,
    selectedTestLength,
    setSelectedTestLength,
    selectedWordList,
    setSelectedWordList,
  } = useContext(SuperMainContentContext);

  const handleTestLengthChange = (event) => {
    setSelectedTestLength(event.target.value);
    console.log(event.target.value);
    setIsFocused(true);
  };

  const handleTestLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    clearPatternBoxes();
    console.log(event.target.value);
    setIsFocused(true);
  };

  const handleTestWordListChange = (event) => {
    setSelectedWordList(event.target.value);
    console.log(event.target.value);
    setIsFocused(true);
  };

  return (

    <Stack
      direction={isSmallScreen ? 'column' : 'row'}
      spacing={6}
      sx={{ display: 'flex', alignItems: 'flex-end', mx: '1em', my: '1.0em' }}
    >
      <TTDropdownMenu title="Language"
        options={languages}
        defaultOption={defaultLanguage}
        onChange={handleTestLanguageChange}
        value={selectedLanguage || defaultLanguage}
        />
      <TTDropdownMenu title="Word List"
        options={wordListSizes}
        defaultOption={defaultWordList}
        onChange={handleTestWordListChange}
        value={selectedWordList || defaultWordList}
      />      
      <TTDropdownMenu title="Test Length"
        options={testLengths}
        defaultOption={defaultTestLength}
        onChange={handleTestLengthChange}
        value={selectedTestLength || defaultTestLength}
      />
    </Stack>

  );
}

export { TypingTestOptions };
