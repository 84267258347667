
const historyToolTip =
`The "Weakness History" setting determines the number of recent tests used to analyze your typing patterns and generate new practice tests.`;

const wordsHistoryToolTip =
`The "Words History" setting determines how many tests pass before a word can be reused. A higher value means words are "forgotten" and can reappear after more tests, increasing variety.`;

const randomnessTooltip =
`The "Randomness" setting controls how many random words are included in your typing tests.`

const refreshIntervalTooltip =
`The "Tests in a session" slider sets how many tests you complete before your list of typing weaknesses is completely cleared and a new session begins.`

const slowPatternsTemplatesTooltip =
`The "Slow Patterns Detection" box allows you to select the types of patterns you want to focus on in your typing tests. The options are "Letters", "Bigrams", "Trigrams", and "Words".`;

const inaccuratePatternsTemplatesTooltip =
`The "Mistyped Patterns Detection" box allows you to select the types of patterns you want to focus on in your typing tests. The options are "Letters", "Bigrams", "Trigrams", and "Words".`;

const freezeTooltip =
`The "Freeze Current Weaknesses" switch allows you to keep your current typing weaknesses indefinitely. This is useful if you want to focus on specific patterns for an extended period of time. Otherwise, the weaknesses are very dynamic and recalculated after every test.`;

const saveLoadTooltip =
`Save your custom patterns to a profile, or load a saved profile to quickly switch between different typing practice settings.`;

const missedPatternsTooltip =
`These are your most misstyped patterns, collected per your preference. The current test includes these patterns for you to practice.`;

const slowPatternsTooltip =
`These are your slowest patterns, collected per your preference. The current test includes these patterns for you to practice.`;

const userIncludedPatternsTooltip =
`These are the patterns you want to focus on in your typing tests. The current test includes these patterns for you to practice.`;

const userExcludedPatternsTooltip =
`These are the patterns you want to avoid in your typing tests. The current test excludes these patterns. Even 'random' words take the excludes into account.`;

const randomDurationToolTip =
`The "Random Duration" setting controls how many completely random tests are presented to you for the algorithm to learn your weaknesses. The higher the number, the more accurate the results will be, but it will take longer until you get to practice your weak patterns.`;

const letterLimitToolTip = `The algorithm tends towards long words. This setting limits the length of the words in your typing tests.`;

export {
  historyToolTip, randomnessTooltip, refreshIntervalTooltip,
  slowPatternsTemplatesTooltip, inaccuratePatternsTemplatesTooltip,
  freezeTooltip, saveLoadTooltip, missedPatternsTooltip, slowPatternsTooltip,
  userIncludedPatternsTooltip, userExcludedPatternsTooltip, randomDurationToolTip,
  wordsHistoryToolTip, letterLimitToolTip
};