
import React, { createContext, useState, useEffect, useRef, useContext } from 'react';

import {
  defaultSlowPatternTemplates, defaultInaccuratePatternTemplates,
  defaultDesiredWeaknessHistory, defaultDesiredRefreshInterval,
  defaultInaccuratePatternsCount, defaultSlowPatternsCount,
  defaultDesiredRandomness, defaultRandomDuration, defaultDesiredWordsHistory,
  defaultLetterLimit, 
} from '../utils/defaultValues.js';

import { SuperMainContentContext } from './SuperMainContext.jsx';
import { AppContext } from './AppContext.jsx';

export const MainContentContext = createContext();

const useSavedPatterns = (storageDefault, defaultPatternsArray) => {
  const [patternsArray, setPatternsArray] = useState(() => {
    const savedPatterns = localStorage.getItem(storageDefault);
    // Parse the JSON string; use default if null
    if (savedPatterns === null) {
      return defaultPatternsArray;
    }
    try {
      const patterns = JSON.parse(savedPatterns);
      // Ensure the parsed object is an array
      return Array.isArray(patterns) ? patterns : defaultPatternsArray;
    } catch (error) {
      // In case of error during parsing, fallback to default
      return defaultPatternsArray;
    }
  });
  
  useEffect(() => {
    localStorage.setItem(storageDefault, JSON.stringify(patternsArray));
  }, [patternsArray, storageDefault]);

  return [patternsArray, setPatternsArray]
}

export const MainContentProvider = ({ children }) => {
  const [testMetaData, setTestMetaData] = useState({});
  const [algorithmOutput, setAlgorithmOutput] = useState({});
  const [inaccuratePatterns, setInaccuratePatterns] = useState([]);
  const [slowPatterns, setSlowPatterns] = useState([]);
  const [loadedProfile, setLoadedProfile] = useState('');
  const [WPM, setWPM] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [testCount, setTestCount] = useState(0);
  const [freeze, setFreeze] = useState(false);
  const [profileOptions, setProfileOptions] = useState([]);
  const [isTestActive, setIsTestActive] = useState(false);

  const [randomDuration, setRandomDuration] = useState(() => {
    const savedRandomDuration = localStorage.getItem('randomDuration');
    const parsedRandomDuration = parseInt(savedRandomDuration, 10);
    return !isNaN(parsedRandomDuration) && parsedRandomDuration >= 0 ?
      parsedRandomDuration : defaultRandomDuration;
  });

  const {
    setExcludedPatterns,
    setIncludedPatterns,
  } = useContext(SuperMainContentContext);

  const clearPatternBoxes = () => {
    setInaccuratePatterns([]);
    setSlowPatterns([]);
    setIncludedPatterns([]);
    setExcludedPatterns([]);
  };

  useEffect(() => {
    localStorage.setItem('randomDuration', randomDuration.toString());
  }, [randomDuration]);

  const [inaccuratePatternsNum, setInaccuratePatternsNum] = useState(() => {
    const savedInaccuratePatternsNum = localStorage.getItem('inaccuratePatternsNum');
    const parsedInaccuratePatternsNum = parseInt(savedInaccuratePatternsNum, 10);
    return !isNaN(parsedInaccuratePatternsNum) && parsedInaccuratePatternsNum >= 0 ?
      parsedInaccuratePatternsNum : defaultInaccuratePatternsCount;
  });

  useEffect(() => {
    localStorage.setItem('inaccuratePatternsNum', inaccuratePatternsNum.toString());
  }, [inaccuratePatternsNum]);

  const [slowPatternsNum, setSlowPatternsNum] = useState(() => {
    const savedslowPatternsNum = localStorage.getItem('slowPatternsNum');
    const parsedslowPatternsNum = parseInt(savedslowPatternsNum, 10);
    return !isNaN(parsedslowPatternsNum) && parsedslowPatternsNum >= 0 ?
      parsedslowPatternsNum : defaultSlowPatternsCount;
  });

  useEffect(() => {
    localStorage.setItem('slowPatternsNum', slowPatternsNum.toString());
  }, [slowPatternsNum]);


  const [inaccuratePatternTemplates, setInaccuratePatternTemplates] =
    useSavedPatterns('inaccuratePatternTemplates', defaultInaccuratePatternTemplates);

  const [slowPatternTemplates, setSlowPatternTemplates] =
    useSavedPatterns('slowPatternTemplates', defaultSlowPatternTemplates);

  const [desiredWeaknessHistory, setDesiredWeaknessHistory] = useState(() => {
    const savedHistory = localStorage.getItem('desiredWeaknessHistory');
    const parsedHistory = parseInt(savedHistory, 10);
    return !isNaN(parsedHistory) && parsedHistory >= 0 ? parsedHistory : defaultDesiredWeaknessHistory;
  });

  useEffect(() => {
    localStorage.setItem('desiredWeaknessHistory', desiredWeaknessHistory.toString());
  }, [desiredWeaknessHistory]);

  const [desiredWordsHistory, setDesiredWordsHistory] = useState(() => {
    const savedWordsHistory = localStorage.getItem('desiredWordsHistory');
    const parsedWordsHistory = parseInt(savedWordsHistory, 10);
    return !isNaN(parsedWordsHistory) && parsedWordsHistory >= 0 ? parsedWordsHistory : defaultDesiredWordsHistory;
  }
  );

  useEffect(() => {
    localStorage.setItem('desiredWordsHistory', desiredWordsHistory.toString());
  } , [desiredWordsHistory]);
  


  const [desiredRefreshInterval, setDesiredRefreshInterval] = useState(() => {
    const savedRefreshInterval = localStorage.getItem('desiredRefreshInterval');
    const parsedRefreshInterval = parseInt(savedRefreshInterval, 10);
    return !isNaN(parsedRefreshInterval) > 0 ? parsedRefreshInterval : defaultDesiredRefreshInterval;
  });

  useEffect(() => {
    localStorage.setItem('desiredRefreshInterval', desiredRefreshInterval.toString());
  }, [desiredRefreshInterval]);

  // useEffect(() => {
  //   setTestCount(0);
  // }, [desiredRefreshInterval, randomDuration]);

  // Create an object with all the state variables and their setters
  const contextValue = {
    testMetaData, setTestMetaData,
    algorithmOutput, setAlgorithmOutput, inaccuratePatterns, setInaccuratePatterns,
    slowPatterns, setSlowPatterns, slowPatternTemplates, setSlowPatternTemplates,
    inaccuratePatternTemplates, setInaccuratePatternTemplates, loadedProfile, setLoadedProfile,
    WPM, setWPM, accuracy, setAccuracy, desiredWeaknessHistory, setDesiredWeaknessHistory,
    desiredRefreshInterval, setDesiredRefreshInterval, testCount, setTestCount, 
    inaccuratePatternsNum, setInaccuratePatternsNum, slowPatternsNum, setSlowPatternsNum,
    freeze, setFreeze,  randomDuration, setRandomDuration, desiredWordsHistory,
    setDesiredWordsHistory, profileOptions, setProfileOptions, isTestActive, setIsTestActive,
    clearPatternBoxes
  };

  return (
    <MainContentContext.Provider value={contextValue}>
      {children}
    </MainContentContext.Provider>
  );
};
