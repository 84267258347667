import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProvider } from './contexts/AppContext'

// import { Amplify } from 'aws-amplify';
// import { Auth0Provider } from '@auth0/auth0-react';

//Change console.log to not do anything
//When you run npm start, NODE_ENV is set to 'development'
//When you run npm run build, NODE_ENV is set to 'production'
//When you run npm test, NODE_ENV is set to 'test'
if (process.env.NODE_ENV === 'production') {
  console.log = function() {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);

// <Auth0Provider
//   domain="dev-2wfsb47jl1geix3y.us.auth0.com"
//   clientId="iGJBCjFoIaRSMFglL4fpNyWzcfs4FqRj"
//   authorizationParams={{
//     redirect_uri: window.location.origin
//   }}
// >
// </Auth0Provider>