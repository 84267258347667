import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { debounce } from 'lodash'; // Make sure to import lodash or implement your own debounce function
import { Paper, Box, CircularProgress, Stack, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Metadata, DesiredWeaknessPatterns, HistoryManager } from '../utils/mainAlgorithm';
// import { checkIfNewUserRecord } from '../utils/userRecords.js';
import { wordCounts } from '../utils/defaultValues';
//TODO fix the lists with this - pretty awesome
// https://www.online-spellcheck.com/result/c32e687b3b200dab09833861234394569d624ab9

import styles from './TypingTest.module.css'
import { TTDialog } from './TTDialog'
import { TypingTip } from './TypingTip'
import { MemoizedWord,  } from './Word'
import { Caret } from './Caret'
import SkeletonStack from './SkeletonStack'

import { MainContentContext } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { getTestConfiguration} from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext.jsx'


const validCharacters = {
  Arabic: /^[ء-ي0-9,. '"-:;]$/,
  Chinese: /^.$/,
  English: /^[a-zA-Z0-9,. '"-:;]$/,
  French: /^[a-zA-Z0-9,. '"-:;àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸŒ]$/,
  German: /^[a-zA-Z0-9,. '"-:;äöüÄÖÜß]$/,
  Hebrew: /^[א-ת0-9,. '"-:;]$/,
  Italian: /^[a-zA-Z0-9,. '"-:;àèéìíîòóùúÀÈÉÌÍÎÒÓÙÚ]$/,
  Portuguese: /^[a-zA-Z0-9,. '"-:;áâãàéêíóôõúçÁÂÃÀÉÊÍÓÔÕÚÇ]$/,
  Russian: /^[а-яА-ЯёЁ0-9,. '"-:;]$/,
  Spanish: /^[a-zA-Z0-9,. '"-:;áéíóúñÁÉÍÓÚÑ]$/,
};

const isRTL = (language) => ['Hebrew', 'Arabic'].includes(language);


const TypingTest = ({ onStart, onEnd }) => {
  //Element N in timeToType would be the time it took to reach the Nth
  //character in the typing test, relative to the 0th character
  const metadataRef = useRef(new Metadata());
  const testContainerRef = useRef(null);
  const inputRef = useRef(null);
  const testStart = useRef(true);
  const currWordCount = useRef(0);

  // const [typingTestText, setTypingTestText] = useState('');
  const [userInput, setUserInput] = useState('');
  const { slowPatternTemplates, inaccuratePatternTemplates, setWPM,
    setAccuracy, setInaccuratePatterns, setSlowPatterns, slowPatterns,
    inaccuratePatterns, desiredWeaknessHistory, desiredRefreshInterval, testCount,
    setTestCount, inaccuratePatternsNum, slowPatternsNum, freeze,
    randomDuration, desiredWordsHistory, WPM,
  } = useContext(MainContentContext);

  const { selectedTestLength, selectedWordList, selectedLanguage,
    includedPatterns, excludedPatterns, testAnalysisRef, typingTestText,
    setTypingTestText, desiredRandomness, getRandomTest,
    isFullyRandom, setAlgorithmFoundWords, letterLimit,
    isLoading, setIsLoading, randomPhase
  } = useContext(SuperMainContentContext)

  const { isFocused, setIsFocused, currUser } = useContext(AppContext);

  const [typingTipDialog, setTypingTipDialog] = useState(false);
  const [keyStates, setKeyStates] = useState({});
  const [isInputLocked, setIsInputLocked] = useState(false);
  const { isTestActive, setIsTestActive } = useContext(MainContentContext);
  const theme = useTheme();
  const handleOpentypingTipDialog = () => setTypingTipDialog(true);
  const handleClosetypingTipDialog = () => setTypingTipDialog(false);
  const [caretPosition, setCaretPosition] = useState(null);
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [shouldBlinkCaret, setShouldBlinkCaret] = useState(true);

  const isTyping = useRef(false);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isFocused) {
      focusInput();
    }
    // console.log("isFocused = ", isFocused)
  }, [isFocused]);

  useEffect(() => {
    const handleMouseMove = () => {
      if (isTestActive) {
        setIsCursorVisible(true);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    //useEffect 'cleanup' function
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isTestActive]);

  useEffect(() => {
    if (isTestActive) {
      setIsCursorVisible(false);
    } else {
      setIsCursorVisible(true);
    }
  }, [isTestActive]);

  useEffect(() => {
    if (isCursorVisible) {
      document.body.style.cursor = 'auto';
    } else {
      document.body.style.cursor = 'none';
    }

    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [isCursorVisible]);

  const updateCaretPosition = useCallback(() => {
    const currentLetter = document.querySelector(`[data-index="${userInput.length}"]`);
    if (currentLetter && testContainerRef.current) {
      const containerRect = testContainerRef.current.getBoundingClientRect();
      const letterRect = currentLetter.getBoundingClientRect();
      setCaretPosition({
        left: letterRect.left - containerRect.left,
        top: letterRect.top - containerRect.top,
        width: letterRect.width,
        height: letterRect.height
      });
    }
  }, [userInput.length]);

  useEffect(() => {
    updateCaretPosition();
    window.addEventListener('resize', updateCaretPosition);
    return () => window.removeEventListener('resize', updateCaretPosition);
  }, [updateCaretPosition]);

  useEffect(() => {
    updateCaretPosition();
  }, [typingTestText]);

  const debouncedUpdateCaretPosition = useCallback(
    debounce(updateCaretPosition, 16), // Debounce to roughly 60fps
    [updateCaretPosition]
  );

  useEffect(() => {
    if (!isLoading && isFocused) {
      debouncedUpdateCaretPosition();
    }
  }, [userInput, isLoading, isFocused, debouncedUpdateCaretPosition]);

  useEffect(() => {
    // console.log("TypingTest 1st mount useEffect")
    const fetchTextAndFocus = async () => {
      //TODO why did I need this here?
      await resetTest();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleWindowFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
        setIsFocused(true);
      }
    };

    fetchTextAndFocus();
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);



  //A claude callback
  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
    };
    if (isTestActive) {
      // window.addEventListener('wheel', preventDefault, { passive: false });
      window.addEventListener('touchmove', preventDefault, { passive: false });
    }

    return () => {
      window.removeEventListener('wheel', preventDefault);
      window.removeEventListener('touchmove', preventDefault);
    };
  }, [isTestActive]);


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMousePress = (event) => {
    // console.log("handleMousePress")
    if (!isFocused) {
      inputRef.current.focus();
      setIsFocused(true);
    }
  };

  //TODO nimw : I think this is being called even when the typing test is already focused
  const handleBlur = (e) => {
    // console.log("handleBlur")
    //This didn't work
    // if (e.relatedTarget && e.relatedTarget.closest('.typingTest')) {
    //   e.preventDefault(); // Prevent blur if clicked within the same component
    //   return;
    // }
    setIsFocused(false);
  };

  // Function to check if a character is valid based on the language
  const isValidCharacter = (pressedKey, language = 'English') => {
    const regex = validCharacters[language] || validCharacters['English'];
    return regex.test(pressedKey);
  };

  const startOfTest = () => {
    // console.log('Start of test');
    metadataRef.current.originalText = typingTestText;
    metadataRef.current.startTime = Date.now();
    setIsTestActive(true);
    // scrollToTop();
    setShouldBlinkCaret(false);
    onStart();
    requestAnimationFrame(() => {
      scrollToTop();
      console.log('Start of test');
    });
  };

  const prepareDesiredPatterns = (desiredPatterns, pressedKey) => {
    console.log("End of test");
    if (userInput.length > 0) {
      metadataRef.current.userText = userInput + pressedKey;
    }
    console.log(JSON.parse(JSON.stringify(metadataRef.current)))
    setTestCount(prevTestCount => prevTestCount + 1);

    console.log(`Test count: ${testCount + 1}, desiredRefreshInterval: ${desiredRefreshInterval}, randomDuration: ${randomDuration}`);

    //TODO nimw: why is this here and not in ProgressBar component?
    if (((testCount + 1) % desiredRefreshInterval) < randomDuration) {
      randomPhase.current = true;
    }
    else {
      randomPhase.current = false;
    }
    console.log("randomPhase = ", randomPhase.current)

    desiredPatterns.slowLetters = slowPatternTemplates.includes("Letters");
    desiredPatterns.slowBigrams = slowPatternTemplates.includes("Bigrams");
    desiredPatterns.slowTrigrams = slowPatternTemplates.includes("Trigrams");
    desiredPatterns.slowWords = slowPatternTemplates.includes("Words");
    desiredPatterns.errorneousLetters = inaccuratePatternTemplates.includes("Letters");
    desiredPatterns.errorneousBigrams = inaccuratePatternTemplates.includes("Bigrams");
    desiredPatterns.errorneousTrigrams = inaccuratePatternTemplates.includes("Trigrams");
    desiredPatterns.errorneousWords = inaccuratePatternTemplates.includes("Words");
    console.log(JSON.parse(JSON.stringify(desiredPatterns)))
  }

  const endOfTest = async (pressedKey) => {
    let desiredPatterns = new DesiredWeaknessPatterns();
    prepareDesiredPatterns(desiredPatterns, pressedKey);
    const { WPM, accuracy } =
      testAnalysisRef.current.getPerformanceMetrics(metadataRef.current, desiredPatterns,
        desiredWeaknessHistory, setSlowPatterns, setInaccuratePatterns,
        slowPatterns, inaccuratePatterns, includedPatterns, excludedPatterns,
        inaccuratePatternsNum, slowPatternsNum, freeze, desiredWordsHistory);
    setWPM(WPM);
    //Taking care of this in userStatistics
    // if (isFullyRandom.current) {
    //   checkIfNewUserRecord(currUser, WPM, selectedTestLength, selectedWordList, selectedLanguage);
    // }
    setAccuracy(accuracy);
    setIsTestActive(false);
    //TODO nimw: figure out if this is needed.
    // It was added here to allow time for the statistics table to be updated
    //but it's not really making any sense
    setTimeout(() => {
      resetTest();
    }, 100);
  };

  // const checkIfNewUserRecord = (WPM, selectedTestLength, selectedWordList, selectedLanguage) => {
  //TODO rhig: implement this
  // }

  const resetTest = async (refetchText = true) => {
    console.log("resetTest")
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 0));

    currWordCount.current = wordCounts[selectedTestLength];
    try {
      let fetchedText = '';
      if (refetchText) {
        if (((testCount + 1) % desiredRefreshInterval === 0) && testCount !== 0 && !freeze) {
          setInaccuratePatterns([]);
          setSlowPatterns([]);
          getRandomTest();
          testAnalysisRef.current.historyManager = new HistoryManager();
        }
        else {
          if (randomPhase.current) {
            console.log("Random Analysis")
            getRandomTest();
          }
          else {
            console.log("Targeted Practice")
            // Extract the testDuration and wordList from the getTestConfiguration function
            const { wordCount, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
            // Pass the extracted values to getNextTest
            fetchedText = await testAnalysisRef.current.getNextTest(wordCount,
              wordList, desiredRandomness, true, setAlgorithmFoundWords, letterLimit);
            setTypingTestText(fetchedText);
          }
        }
      }
      setUserInput('');
      testStart.current = true;
      metadataRef.current = {
        originalText: '',
        userText: '',
        errorIndices: [],
        startTime: 0,
        timeToType: []
      };
      onEnd();
    } catch (error) {
      console.error("Error resetting test:", error);
      // Handle the error appropriately, maybe set an error state or show a message to the user
    } finally {
      setIsLoading(false);
    }
    setIsTestActive(false);
    setShouldBlinkCaret(true);
  };



  const handlePressedInput = (pressedKey) => {
    if (userInput.length <= typingTestText.length) {
      setUserInput(prevInput => prevInput + pressedKey);
      if (pressedKey !== typingTestText[userInput.length]) {
        console.log("wrong key")
        metadataRef.current.errorIndices.push(userInput.length);
      }
      // Record the current timestamp relative to the start time
      const elapsedTime = Date.now() - metadataRef.current.startTime;
      metadataRef.current.timeToType.push(elapsedTime);
    }
  };

  const handleInput = (pressedKey) => {
    if (isInputLocked) return;

    if (userInput.length === 0 && testStart.current === true) {
      testStart.current = false;
      startOfTest();
    }
    handlePressedInput(pressedKey);

    if (isTestActive && !isCursorVisible) {
      setIsCursorVisible(false);
    }

    if (userInput.length + 1 === typingTestText.length) {
      setIsInputLocked(true);
      setTimeout(() => {
        endOfTest(pressedKey);
        setIsInputLocked(false);
      }, 50);
    }
  };

  const isBackspace = (pressedKey) => pressedKey === 'Backspace';

  const handleBackspace = () => {
    setUserInput(prevInput => prevInput.slice(0, -1));
    metadataRef.current.timeToType.pop();
  };

  const isCtrlBackspace = (event) => (event.ctrlKey || event.altKey) && event.key === 'Backspace';

  const handleCtrlBackspace = () => {
    console.log("ctrl backspace");
    if (userInput.length > 0) {
      let startIndex = userInput.length - 1;
      while (startIndex > 0 && typingTestText[startIndex - 1] !== ' ') {
        startIndex--;
      }
      console.log("index = ", startIndex);

      // Calculate the number of backspaces needed
      const numBackspaces = userInput.length - startIndex;
    
      // Call handleBackspace the required number of times
      for (let i = 0; i < numBackspaces; i++) {
        handleBackspace();
      }
    }
  };


  const isEsc = (event) => event.key === 'Escape';

  const handleEsc = (event) => {
    console.log('handleEsc')
    event.stopPropagation(); // Add this line
    resetTest(false);
  }

  const handleKeyDown = (event) => {
    const pressedKey = event.key;

    //This isn't related to "language", don't be fooled
    if (event.ctrlKey && pressedKey.length === 1 && pressedKey.match(/[a-zA-Z0-9-!@#$%^&*()+]/i)) {
      // Do nothing for ctrl+<letter>
      return;
    }

    if (isBackspace(pressedKey)) {
      if (isCtrlBackspace(event)) {
        handleCtrlBackspace();
      } else {
        handleBackspace();
      }
    }
    // else if (!keyStates[pressedKey]) {
    // Prevent spacebar scrolling when focused
    if (pressedKey === ' ' && isFocused) {
      event.preventDefault();
    }
    if (isValidCharacter(pressedKey, selectedLanguage)) {
      // if (true) {
      handleInput(pressedKey);
    }
    else if (isEsc(event)) {
      handleEsc(event);
    }
    //TODO figure out how to add the keystates to not allow the user to hold down the key
    //without interfering with capital letters usint shift key
    // setKeyStates((prevStates) => ({ ...prevStates, [pressedKey]: true }));
    // }
    if (isTestActive && isValidCharacter(event.key)) {
      setIsCursorVisible(false);
      isTyping.current = true;
    }
  };

  const handleKeyUp = (event) => {
    // const { key } = event;
    // setKeyStates((prevStates) => ({ ...prevStates, [key]: false }));
    isTyping.current = false;
  };

  const setCorrectness = (index) => {
    if (index < userInput.length && userInput[index] === typingTestText[index]) {
      if (metadataRef.current.errorIndices.includes(index)) {
        return "corrected";
      }
      return "correct";
    } else {
      return "incorrect";
    }
  };

  const renderStyledText = () => {
    if (typingTestText.length === 0) {
      return null;
    }
    const wordsWithSpaces = typingTestText.match(/\S+\s*/g) || [];
    let startIndex = 0;
  
    let content = wordsWithSpaces.map((word, index) => {
      const wordComponent = (
        <MemoizedWord
          key={index}
          word={word}
          startIndex={startIndex}
          currentIndex={userInput.length}
          userInput={userInput}
          setCorrectness={setCorrectness}
        />
      );
      startIndex += word.length;
      return wordComponent;
    });

    // THE WALL OF SHAME : Claude literally had me use all of these and still couldn't get it to work
    // if (isRTL(selectedLanguage)) {
    //   content.reverse();
    // }

    // He put these in the return statement of 'renderStyledText'
    // flexDirection: isRTL(selectedLanguage) ? 'row-reverse' : 'row',
    // flexWrap: isRTL(selectedLanguage) ? 'wrap-reverse' : 'wrap',
    // justifyContent: isRTL(selectedLanguage) ? 'flex-end' : 'flex-start',
    // aLignContent: 'flex-start',
      
    //And these in the return statement of 'TypingTest'
    // justifyContent: isRTL(selectedLanguage) ? 'flex-end' : 'flex-start',
    // direction: isRTL(selectedLanguage) ? 'rtl' : 'ltr',

    return (
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        direction: isRTL(selectedLanguage) ? 'rtl' : 'ltr',
      }}>
        {content}
      </Box>
    );
  };


  return (
    <>
      <Paper
        ref={testContainerRef}
        elevation={0}
        onMouseUp={handleMousePress}
        className={isFocused ? styles.focused : styles.blurred}
        data-tour="typing-test"
        sx={{
          position: 'relative',
          borderRadius: '20px',
          borderColor: 'rgba(0, 0, 255, 0)',
          width: '100%',
          mb: 2,
          px: 2,
          py: 2,
          backgroundColor: theme.palette.background.paper,
          display: 'flex',
        }}
      >
        {isLoading ? (
          <SkeletonStack count={currWordCount.current} />
          // <Box
          //   sx={{
          //     display: 'flex',
          //     justifyContent: 'center',
          //     alignItems: 'center',
          //     // height: '100vh', // Adjust the height as needed
          //   }}
          // >
          //   <CircularProgress disableShrink sx={{ color: theme.palette.text.primary }} />
          // </Box>
        )
          : (
            <>
              {renderStyledText(typingTestText, userInput)}
              <Caret position={caretPosition} isLoading={isLoading} isFocused={isFocused} shouldBlink={shouldBlinkCaret} />
            </>
          )}
        { /* TODO nimw: consider making this small and visible for fucked up languages like chinese*/}
        <input
          type="text"
          value={userInput}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          ref={inputRef}
          readOnly
          // style={{ opacity: 0, position: 'absolute' }}
          style={{
            opacity: 0,
            position: 'absolute',
            width: '100%', //To avoid blurring when clicking on the typingTest
            height: '100%', //To avoid blurring when clicking on the typingTest
            top: 0,
            left: 0,
            zIndex: 6, // Ensure that it's above everything, so "onBlur" isn't triggered
            cursor: 'inherit', // Makes it inherit the arrow cursor instead of I-beam
            //Now the cursor will be invisible when the user is typing, even if the cursor is
            //on the input field
          }}
        />
        <TTDialog isOpen={typingTipDialog} onClose={handleClosetypingTipDialog}>
          <TypingTip />
        </TTDialog>
      </Paper>
    </>
  );

};

export { TypingTest }
