
const builtInProfiles = {
  "Top Row":
  {
    "include": ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
    "exclude": ["z", "x", "c", "v", "b", "n", "m"]
  },

  "Left Hand": {
    "include": ["q", "w", "e", "r", "t", "a", "s", "d", "f", "g", "z", "x", "c", "v", "b"],
    "exclude": []
  },
  "Right Hand": {
    "include": ["y", "u", "i", "o", "p", "h", "j", "k", "l", "n", "m"],
    "exclude": [],
  },
  "No Index Fingers": {
    "include": [],
    "exclude": ["r", "t", "y", "u", "f", "g", "h", "j", "c", "v", "b", "n", "m"]
  },
  "Double Letters": {
    "include": [ "aa", "bb", "cc", "dd", "ee", "ff", "gg", "hh", "ii", "jj",
      "kk", "ll", "mm", "nn", "oo", "pp", "qq", "rr", "ss", "tt",
      "uu", "vv", "ww", "xx", "yy", "zz" ],
    "exclude" : []
  },
  "Pinky Workout": {
    "include": ["q", "a", "z", "p", "'"],
    "exclude": []
  },
  "Hard Bigrams": {
    "include": ["ny", "yn", "mu", "um", "un", "nu", "my", "ym", "tb", "bt", "tv", "vt", "rv", "vr", "rb", "br"],
    "exclude": []
  },
  "Common Bigrams": {
    "include": ["th", "he", "in", "en", "nt", "re", "er", "an", "ti", "es", "on", "at", "se", "nd", "or", "ar", "al", "te", "co", "de", "to", "ra", "et", "ed", "it", "sa", "em", "ro"],
    "exclude": []
  }

};

export { builtInProfiles };