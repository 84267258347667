import React, { useState } from 'react';
import { Autocomplete, TextField, Box, IconButton, Paper } from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';


// utility.js
//TODO allow the user to enter multiple patterns at once
function processInputString(input) {
  // Remove unwanted characters (anything not a letter or apostrophe)
  const cleanedInput = input.replace(/[^a-zA-Z']/g, '');
  // Split the cleaned input into an array based on space, period, or comma
  const splitInput = cleanedInput.split(/[\s,.]+/);
  return splitInput.filter(Boolean); // Remove any empty strings that may result from split
}

// For the pattern cards as well as selection of letter/bigram/trigram/word
function TTAutoComplete({
  optionsArr,
  value,
  label,
  placeholder,
  useFreeSolo,
  usePopupIcon = false,
  useReadOnly = false,
  setValues,
}) {

  const theme = useTheme();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !useFreeSolo && usePopupIcon) {
      event.defaultMuiPrevented = true; // Prevent MUI default Enter behavior if not freeSolo
    }
  };

  return (
    <Autocomplete
      forcePopupIcon={usePopupIcon}
      size="small"
      multiple={true}
      options={optionsArr}
      getOptionLabel={(option) => option}
      value={value}
      // defaultValue={defaultValue}
      freeSolo={useFreeSolo || true} //This is always true. Not good. But I'm having issues otherwise.
      readOnly={useReadOnly}
      filterSelectedOptions={true} // This line prevents duplicate entries
      isOptionEqualToValue={(option, value) => option === value} // Use strict equality to compare options and values
      //TODO it would be cool if the user could enter multiple patterns at once
      //with space, period or comma separation
      onChange={(event, newValue) => {
        setValues(newValue)
      }
      }
      {...(optionsArr.length > 0 ? {
        ChipProps: {
          size: 'medium', 
          sx: {
            borderRadius: 1,
            backgroundColor: theme.palette.primary.light,
            fontWeight: 'bold',
          }
        }
      } : {})}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          onKeyDown={handleKeyDown} // Handle key down event
        />
      )}
    />
  );
}

export { TTAutoComplete };