import React, { useContext, useState, useEffect, useRef } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Typography, Tooltip, useTheme } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { VscGraphScatter } from "react-icons/vsc";
import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { AppContext } from '../contexts/AppContext';

const IconWrapper = ({ icon: Icon, progress, tooltipText, tooltipOpen, isFocused, visible }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={tooltipText}
      placement="right-end"
      arrow
      open={tooltipOpen && isFocused && visible}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-20, 0],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: 'nowrap',
            maxWidth: 'none',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '& .MuiTooltip-arrow': {
              color: theme.palette.background.paper,
            },
          },
        },
      }}
    >
      <Icon
        sx={{
          position: 'absolute',
          top: '-50%',
          left: `${progress}%`,
          transform: 'translateX(-50%)',
          transition: 'left 0.3s ease-in-out',
          color: theme.palette.text.primary,
        }}
      />
    </Tooltip>
  );
};

const ProgressBar = ({ visible, icon: Icon }) => {
  const [progress, setProgress] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const theme = useTheme();
  const tooltipTimeoutRef = useRef(null);

  const { desiredRefreshInterval, testCount, randomDuration } = useContext(MainContentContext);
  const { randomPhase } = useContext(SuperMainContentContext);
  const { isFocused, isWebsiteReady } = useContext(AppContext);

  useEffect(() => {
    if (desiredRefreshInterval > 0) {
      setProgress(testCount % desiredRefreshInterval);
    } else {
      setProgress(testCount);
    }
  }, [testCount, desiredRefreshInterval]);

  useEffect(() => {
    setTooltipOpen(false);
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltipOpen(true);
    }, 300);
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, [progress]);

  // Calculate widths for the progress bar sections
  const MIN_RANDOM_WIDTH = 16; // Minimum width percentage for random section
  let randomWidth = MIN_RANDOM_WIDTH;
  let nonRandomWidth = 100 - randomWidth;
  
  // if (desiredRefreshInterval > 0) {
  if (randomDuration > 0) {
    randomWidth = Math.max(MIN_RANDOM_WIDTH, (randomDuration / desiredRefreshInterval) * 100);
  }
  else {
    randomWidth = 0;
  }
  nonRandomWidth = 100 - randomWidth;
  // }

  let singleRandomTestWidth = randomWidth / randomDuration;
  let singleTargetedTestWidth = nonRandomWidth / (desiredRefreshInterval - randomDuration);

  // Calculate progress percentage
  // 0 = start, 100 = end
  let position;

  if (randomPhase.current) {
    position = (progress + 0.5) * singleRandomTestWidth;
  } else {
    let targetedProgress = progress - randomDuration;
    position = randomWidth + (targetedProgress + 0.5) * singleTargetedTestWidth;
  }

  if (desiredRefreshInterval === 0) {
    position = 50; // Center position for infinite mode
  }

  const tooltipText = desiredRefreshInterval === 0 ?
    `Test: ${progress + 1}/∞ in session.${progress === 0 ?
      ' Start typing the words you see in the box.' : ''}`
    : `Test: ${progress + 1}/${desiredRefreshInterval} in session.${progress === 0 ?
      ' Start typing the words you see in the box.' : ''}`;

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '1em', display: 'flex', mb: "1em" }}>
      {randomDuration > 0 &&
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.info.light, 0.2),
            borderRadius: '20px 0 0 20px',
            height: "100%",
            width: `${randomWidth}%`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VscGraphScatter />
          {randomWidth > 15 && (
            <Typography variant="body2" sx={{ ml: 1 }}>Random Analysis</Typography>
          )}
        </Box>
      }
      <Box
        sx={{
          backgroundColor: alpha(theme.palette.success.light, 0.2),
          borderRadius: randomDuration > 0 ? '0 20px 20px 0' : '20px',
          height: "100%",
          width: `${nonRandomWidth}%`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {desiredRefreshInterval === 0 && <AllInclusiveIcon />}
        <TimelineIcon />
        {nonRandomWidth > 15 && (
          <Typography variant="body2" sx={{ ml: 1 }}>Targeted Practice</Typography>
        )}
      </Box>
      <IconWrapper
        icon={Icon}
        progress={position}
        tooltipText={tooltipText}
        tooltipOpen={tooltipOpen && isWebsiteReady}
        isFocused={isFocused}
        visible={visible}
      />
    </Box>
  );
};

export default ProgressBar;