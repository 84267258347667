import React, { useState, useRef} from 'react';
import { Autocomplete, TextField, Box, IconButton, Chip } from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import CustomChip from './CustomChip.jsx';

const CustomAutoComplete = ({
  optionsArr,
  value,
  label,
  placeholder,
  useFreeSolo,
  usePopupIcon = false,
  useReadOnly = false,
  setValues,
}) => {

  // const [inputValue, setInputValue] = useState('');

  const handleEdit = (oldValue, newValue) => {
    const newValues = value.map(v => v === oldValue ? newValue : v);
    setValues(newValues);
  };

  const handleBlur = (event) => {
    const inputValue = event.target.value;
    if (inputValue.trim() && !value.includes(inputValue.trim())) {
      setValues([...value, inputValue.trim()]);
      event.target.value = ''; // Clear the input after creating chip
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value.trim()) {
      const newValue = event.target.value.trim();
      if (!value.includes(newValue)) {
        setValues([...value, newValue]);
      }
      event.preventDefault();
      event.target.value = '';
    }
  };

  return (
    <Autocomplete
      multiple
      options={optionsArr}
      getOptionLabel={(option) => option}
      value={value}
      freeSolo={useFreeSolo || true}
      readOnly={useReadOnly}
      filterSelectedOptions={true}
      forcePopupIcon={usePopupIcon}
      onChange={(event, newValue) => setValues(newValue)}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <CustomChip
            key={index}
            label={option}
            onDelete={() => {
              const newValue = value.filter((_, i) => i !== index);
              setValues(newValue);
            }}
            onEdit={handleEdit}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          size="small"
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
};

export default CustomAutoComplete;