import React, { useContext } from 'react';
import {
  signInWithRedirect,
  GoogleAuthProvider,
  signOut,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence
} from "firebase/auth";
import { Button, Avatar, Tooltip } from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import GoogleIcon from '@mui/icons-material/Google';
import { auth } from '../utils/firebaseInit';
import { createUserDocument, checkUserExists } from '../utils/firestoreUtils';

function AuthButton() {
  const { currUser, setCurrUser } = useContext(AppContext);

  const allowedEmails = [
    'nimrodnimrodweinberg@gmail.com',
    'nimrodweinberg@gmail.com',
    'tobivargus@gmail.com',
    'typecelerate@gmail.com',
    'xanderglenn@gmail.com'

  ];

  const handleSignIn = async () => {
    console.log("Starting sign-in process");
    const provider = new GoogleAuthProvider();
    try {
      await setPersistence(auth, browserLocalPersistence);
      console.log("Attempting sign-in with popup");
      const result = await signInWithPopup(auth, provider);


      // Check if user's email is allowed
      if (!allowedEmails.includes(result.user.email)) {
        // Sign them out if not allowed
        await signOut(auth);
        console.log("Unauthorized email");
        alert("Sorry, this app is currently in testing mode and access is limited.");
        return;
      }
      console.log("Sign-in successful:", result.user);
    
      // Create/update user document in Firestore
      const userExists = await checkUserExists(result.user.uid);
      if (!userExists) {
        await createUserDocument(result.user);
      }
    
      setCurrUser(result.user);
    } catch (error) {
      console.error("Error signing in with popup:", error.message);
      console.log("Full error details:", JSON.stringify(error, null, 2));
    
      console.log("Popup was blocked or cancelled, attempting redirect...");
      try {
        await signInWithRedirect(auth, provider);
        console.log("Redirect sign-in initiated");
      } catch (redirectError) {
        console.error("Error initiating redirect sign-in:", redirectError.message);
        console.log("Full redirect error details:", JSON.stringify(redirectError, null, 2));
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setCurrUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <Tooltip title={currUser ? `Logout ${currUser.displayName}` : "Login"}>
      <Button
        data-tour="auth-button"
        variant="contained"
        startIcon={
          currUser ? (
            currUser.photoURL ? (
              <Avatar src={currUser.photoURL} alt={currUser.displayName} sx={{ width: 24, height: 24 }} />
            ) : (
              <FontAwesomeIcon icon={faRightFromBracket} flip="horizontal" />
            )
          ) : (
            <GoogleIcon />
          )
        }
        onClick={currUser ? handleSignOut : handleSignIn}
      >
        {currUser ? 'Logout' : 'Login'}
      </Button>
    </Tooltip>
  );
};

export { AuthButton };