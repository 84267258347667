import React, { useState, useContext, useCallback, useEffect } from 'react';
import { TextField, MenuItem, Select, IconButton, Box, Button, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext';
import { getProfiles, addProfile, deleteProfile, modifyProfile, getProfile } from '../utils/firestoreUtils';
import { builtInProfiles } from '../utils/builtInProfiles';

//TODO nimw - Claude suggested I use useReducer for this component
//It even gave me working code (I tested manually), but I don't want to do it before I understand it

// For the saving/loading profile part
const ProfilesMenu = () => {
  const {profileOptions, setProfileOptions} = useContext(MainContentContext);
  const [currProfile, setCurrProfile] = useState('');
  const { loadedProfile, setLoadedProfile } = useContext(MainContentContext);
  const { setIncludedPatterns, includedPatterns, setExcludedPatterns, excludedPatterns } = useContext(SuperMainContentContext);
  const { currUser, isUserLoading } = useContext(AppContext);


  const handleProfileNameChange = (event) => {
    setCurrProfile(event.target.value);
  };

  //setOptions according to the profiles in the database, if first ever login, setOptions to builtInProfiles
  useEffect(() => {
    if (isUserLoading || !currUser) {
      return;
    }
    getProfiles(currUser.uid).then((profiles) => {
      if (profiles.length === 0) {
        // First login - create built-in profiles
        Object.entries(builtInProfiles).forEach(([name, profileData]) => {
          addProfile(currUser.uid, {
            name,
            include: profileData.include,
            exclude: profileData.exclude
          });
        });
        // Set options to built-in profile names
        setProfileOptions(Object.keys(builtInProfiles));
      } else {
        // Extract profile names from the id field of each profile object
        setProfileOptions(profiles.map(profile => profile.id));
      }
    });
  }, [currUser, isUserLoading]);


  const handleProfileNameKeyPress = (event) => {
    if ((event.key === 'Enter' || event.type === 'click') && currProfile.trim() !== '') {
      setProfileOptions([...profileOptions, currProfile.trim()]);
      setCurrProfile('');
      setLoadedProfile(currProfile.trim());
      addProfile(currUser.uid, {
        include: includedPatterns,
        exclude: excludedPatterns,
        name: currProfile.trim()
      });
      // addOptionToDB(inputValue.trim(), includedPatterns, excludedPatterns);
    }
  };

  // const getIncludeArrFromDB = (selectedOption) => {
  //   return additionalOptions[selectedOption]?.include || [];
  // }

  // const getExcludeArrFromDB = (selectedOption) => {
  //   return additionalOptions[selectedOption]?.exclude || [];
  // }

  const handleOptionClick = (selectedOption) => {
    setLoadedProfile(selectedOption);
    getProfile(currUser.uid, selectedOption).then((profile) => {
      setIncludedPatterns(profile.include);
      setExcludedPatterns(profile.exclude);
    });
  };

  const handleDeleteOption = (index) => {
    const optionToDelete = profileOptions[index];
    const updatedOptions = profileOptions.filter((_, i) => i !== index);
    setProfileOptions(updatedOptions);
    deleteProfile(currUser.uid, optionToDelete);
    if (loadedProfile === optionToDelete) {
      setLoadedProfile('');
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid xs={8}>
          <TextField
            label="Create New Profile..."
            value={currProfile}
            onChange={handleProfileNameChange}
            onKeyPress={handleProfileNameKeyPress}
            size="small"
            fullWidth
            disabled={!currUser}
          />
        </Grid>
        <Grid xs={4}>
          <Button
            variant='contained'
            fullWidth
            onClick={handleProfileNameKeyPress}
            disabled={!currUser}
          >
            <Typography align='left'>Create</Typography>
          </Button>
        </Grid>
        <Grid xs={12}>
          <Select
            value=""
            onChange={(event) => handleOptionClick(event.target.value)}
            displayEmpty
            variant="outlined"
            size="small"
            fullWidth
            disabled={!currUser}
          >
            <MenuItem value="" disabled>
              {loadedProfile === ''
                  ? 'Load Profile...'
                  : loadedProfile
              }
            </MenuItem>
            {profileOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  {option}
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteOption(index);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            variant='contained'
            onClick={() => modifyProfile(currUser.uid, {
              include: includedPatterns,
              exclude: excludedPatterns,
              name: loadedProfile
            })}
            disabled={!currUser}
          >
            <Typography>Modify Current Profile</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

};

export { ProfilesMenu};
