import React from 'react';
import { Typography, Box, Link, Stack } from '@mui/material';

// const friends = [
//   { name: 'Rhig', link: 'https://www.google.com' },
//   { name: 'Guti', link: 'https://www.google.com' },
//   { name: 'Paramewomewo', link: 'https://www.google.com' },
//   { name: 'Reddit Typing Community', link: 'https://www.reddit.com/r/Typing' },
// ]

const otherContributors = [
  { name: 'Maximillian Schwarzmueller',  link: 'https://maximilian-schwarzmueller.com/'},
  { name: 'Claude', link: 'https://Claude.ai' }, 
  { name: 'ChatGPT', link: 'https://chat.openai.com/' }, 
  { name: 'Github Copilot', link: 'https://github.com/features/copilot' }, 
  { name: 'Visual Studio Code', link: 'https://code.visualstudio.com/' }, 
  { name: 'Windows Subsystem for Linux', link: 'https://ubuntu.com/desktop/wsl' }, 
  { name: 'VSCodeVIM', link: 'https://github.com/VSCodeVim/Vim' }, 
]

const openSourceLibraries = [
  { name: 'React', link: 'https://reactjs.org/' },
  { name: 'Material-UI', link: 'https://mui.com/' },
  { name: 'Google Fonts', link: 'https://fonts.google.com/' },
  { name: 'Lodash', link: 'https://lodash.com/' },
  { name: 'Helmet', link: 'https://helmetjs.github.io/' },
  { name: 'reactour', link: 'https://reactour.js.org/' },
  { name: 'html2canvas', link: 'https://html2canvas.hertzen.com/' },
  { name: 'framer-motion', link: 'https://www.framer.com/motion/' },
]

const Contributors = () => {
  return (
    <Stack spacing={4}>
      {/* <Box>
        <Typography variant="h4">
          Friends who helped
        </Typography>
        {friends.map((contributor, index) => (
          <Box key={index}>
            <Link href={contributor.link} color="textPrimary" target="_blank" rel="noopener noreferrer">
              {contributor.name}
            </Link>
          </Box>
        ))}
      </Box> */}
      <Box>
        <Typography variant="h4">
          I couldn't have done it without
        </Typography>
        {otherContributors.map((contributor, index) => (
          <Box key={index}>
            <Link href={contributor.link} color="textPrimary" target="_blank" rel="noopener noreferrer">
              {contributor.name}
            </Link>
          </Box>
        ))}
      </Box>
      <Box>
        <Typography variant="h4">
          Open Source Libraries Used
        </Typography>
        {openSourceLibraries.map((contributor, index) => (
          <Box key={index}>
            <Link href={contributor.link} color="textPrimary" target="_blank" rel="noopener noreferrer">
              {contributor.name}
            </Link>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}

export default Contributors;