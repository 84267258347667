import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { debounce } from 'lodash'; // Make sure to import lodash or implement your own debounce function


import { SingleTestAnalysis, HistoryManager } from '../utils/mainAlgorithm';
import { defaultDesiredRandomness, wordCounts, languageCodes,
  wordListSizes, testLengths, defaultLanguage, defaultWordList,
  defaultTestLength, defaultLetterLimit } from '../utils/defaultValues';
import avatarIcons from '../utils/avatarIcons.js';

import wordLists from '../assets/wordLists.js';

export function getTestConfiguration(selectedTestLength, selectedWordList, language = 'English') {
  const wordCount = wordCounts[selectedTestLength];
  if (wordCount === undefined) {
    throw new Error("Invalid test length");
  }

  const languageCode = languageCodes[language];
  if (!languageCode) {
    throw new Error("Invalid language");
  }

  if (!wordListSizes.includes(selectedWordList)) {
    throw new Error("Invalid word list size");
  }

  const wordListKey = `${languageCode}_${selectedWordList}`;
  const wordList = wordLists[wordListKey];

  if (!wordList) {
    throw new Error("Word list not found for the specified language and size");
  }

  return { wordCount, wordList };
}

export const SuperMainContentContext = createContext();
export const SuperMainProvider = ({ children }) => {
const isFullyRandom = useRef(false);
const [testStatus, setTestStatus] = useState('');
const testAnalysisRef = useRef(new SingleTestAnalysis(isFullyRandom, setTestStatus));
const [typingTestText, setTypingTestText] = useState('');
const [algorithmFoundWords, setAlgorithmFoundWords] = useState(true); 
const [isLoading, setIsLoading] = useState(true)
const isInitialMount = useRef(true);
const randomPhase = useRef(true);
const [showNewRecord, setShowNewRecord] = useState(false);
// const [avatarIcon, setAvatarIcon] = useState(ElderlyWomanIcon); 
//TODO nimw : keep in localStorage (and later in DB), together with the theme
  const [avatarIcon, setAvatarIcon] = useState(() => {
    const savedIconName = localStorage.getItem('avatarIcon');
    const savedIcon = avatarIcons.find(icon => icon.name === savedIconName);
    return savedIcon ? savedIcon.icon : TwoWheelerIcon;
  });

  useEffect(() => {
    const iconName = avatarIcons.find(icon => icon.icon === avatarIcon)?.name;
    if (iconName) {
      localStorage.setItem('avatarIcon', iconName);
    }
  }, [avatarIcon]);

  const [desiredRandomness, setDesiredRandomness] = useState(() => {
  const savedRandomness = localStorage.getItem('desiredRandomness');
    // Explicitly check for null to differentiate between "0" and "doesn't exist"
    if (savedRandomness === null) {
      return defaultDesiredRandomness; // Default value when nothing is saved
    }
    const parsedRandomness = parseInt(savedRandomness, /*radix*/10);
    // Use parsed value directly as we've already handled the null case
    return !isNaN(parsedRandomness) ? parsedRandomness : defaultDesiredRandomness;
  });

  useEffect(() => {
    // Save the value as a string; "0" is truthy here so it will be saved correctly
    localStorage.setItem('desiredRandomness', desiredRandomness.toString());
  }, [desiredRandomness]);
  const [excludedPatterns, setExcludedPatterns] = useState([]
    /*() => {
    const savedPatterns = localStorage.getItem('excludedPatterns');
    return savedPatterns ? savedPatterns.split(',') : [];
    }*/
  );

  // useEffect(() => {
  //   localStorage.setItem('excludedPatterns', excludedPatterns.toString());
  // }, [excludedPatterns]);

  const [includedPatterns, setIncludedPatterns] = useState([]
    /* () => {
    const savedPatterns = localStorage.getItem('includedPatterns');
    return savedPatterns ? savedPatterns.split(',') : [];
    }*/
  );

  // useEffect(() => {
  //   localStorage.setItem('includedPatterns', includedPatterns.toString());
  // }, [includedPatterns]);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || defaultLanguage;
  });

  useEffect(() => {
    localStorage.setItem('language', selectedLanguage);
  }, [selectedLanguage]);


  const [selectedStatisticsLanguage, setSelectedStatisticsLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('statisticsLanguage');
    return savedLanguage || defaultLanguage;
  });

  useEffect(() => {
    localStorage.setItem('statisticsLanguage', selectedStatisticsLanguage);
  }, [selectedStatisticsLanguage]);

  const [selectedWordList, setSelectedWordList] = useState(() => {
    const savedWordList = localStorage.getItem('wordList');
    return wordListSizes.includes(savedWordList) ? savedWordList : defaultWordList;
  });

  useEffect(() => {
    localStorage.setItem('wordList', selectedWordList);
  }, [selectedWordList]);

  const [selectedTestLength, setSelectedTestLength] = useState(() => {
    const savedTestLength = localStorage.getItem('testLength');
    return testLengths.includes(savedTestLength) ? savedTestLength : defaultTestLength;
  });

  const [letterLimit, setLetterLimit] = useState(() => {
    const savedLetterLimit = localStorage.getItem('letterLimit');
    const parsedLetterLimit = parseInt(savedLetterLimit, 10);
    return !isNaN(parsedLetterLimit) && parsedLetterLimit >= 0 ?
      parsedLetterLimit : defaultLetterLimit;
  });

  useEffect(() => {
    localStorage.setItem('letterLimit', letterLimit.toString());
  } , [letterLimit]);

  const [patternsAccordionExpanded, setPatternsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('patternsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('patternsAccordionExpanded', patternsAccordionExpanded.toString());
  }, [patternsAccordionExpanded]);

  const [statisticsAccordionExpanded, setStatisticsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('statisticsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('statisticsAccordionExpanded', statisticsAccordionExpanded.toString());
  }, [statisticsAccordionExpanded]);

  useEffect(() => {
    localStorage.setItem('testLength', selectedTestLength);
  }, [selectedTestLength]);


  const clearHistoryManager = () => {
    testAnalysisRef.current.clearHistory();
  };

  const getRandomTest = async () => {
    console.log("getRandomTest")
    let fetchedText = '';
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 0));
    testAnalysisRef.current.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    const { wordCount, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
    fetchedText = await testAnalysisRef.current.getNextTest(wordCount, wordList, 100, true, setAlgorithmFoundWords);
    setTypingTestText(fetchedText);
    setIsLoading(false);
  };

  const resetLesson = useCallback(() => {
    if (testAnalysisRef.current) {
      console.log("Resetting lesson");
      // Clear patterns and history
      testAnalysisRef.current.historyManager = new HistoryManager();
      // Reset to random phase
      isFullyRandom.current = true;
      // Get a new random test
      getRandomTest();
    }
  }, []);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     console.log("Basic test parameters changed - resetting lesson");
  //     resetLesson();
  //   }
  // }, [selectedLanguage, selectedWordList, selectedTestLength]);


const lastSettingsRef = useRef(null);

const getNewTestBasedOnUserPatternsChanges = useCallback(async () => {
  console.log("getNewTestBasedOnUserPatternsChanges")

  // If we're in random phase, only regenerate if non-pattern settings changed
  if (randomPhase.current) {
    // Skip test regeneration if only patterns changed
    const settingsChanged = 
      !lastSettingsRef.current || 
      selectedTestLength !== lastSettingsRef.current.selectedTestLength ||
      selectedWordList !== lastSettingsRef.current.selectedWordList ||
      desiredRandomness !== lastSettingsRef.current.desiredRandomness ||
      letterLimit !== lastSettingsRef.current.letterLimit;

    console.log("Settings change check - changed:", settingsChanged, {
      testLength: selectedTestLength,
      wordList: selectedWordList,
      randomness: desiredRandomness,
      letterLimit: letterLimit,
      prevTestLength: lastSettingsRef.current?.selectedTestLength,
      prevWordList: lastSettingsRef.current?.selectedWordList,
      prevRandomness: lastSettingsRef.current?.desiredRandomness,
      prevLetterLimit: lastSettingsRef.current?.letterLimit
    });

    if (!settingsChanged) {
      return;  // Skip everything if no relevant changes
    }
    
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 0));
    getRandomTest();
  } else {
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 0));
    testAnalysisRef.current.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    const { wordCount, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
    const fetchedText = await testAnalysisRef.current.getNextTest(wordCount,
      wordList, desiredRandomness, false, setAlgorithmFoundWords, letterLimit);
    setTypingTestText(fetchedText);
  }

  // Store current settings for next comparison
  lastSettingsRef.current = {
    selectedTestLength,
    selectedWordList,
    desiredRandomness,
    letterLimit
  };

  setIsLoading(false);
});

  // Claude wrote this debounced version
  const DEBOUNCE_DELAY = 500; // Static 500ms delay
  const debouncedGetNewTestRef = useRef(null);

  useEffect(() => {
    // Create the debounced function
    debouncedGetNewTestRef.current = debounce(() => {
      console.log("Fetching new test based on changes to requested user patterns");
      getNewTestBasedOnUserPatternsChanges().catch(error => {
        console.error("Error fetching new test based on user patterns changes: ", error);
      });
    }, DEBOUNCE_DELAY);

    // Cleanup function to cancel any pending debounced calls
    return () => {
      if (debouncedGetNewTestRef.current) {
        debouncedGetNewTestRef.current.cancel();
      }
    };
  }, [getNewTestBasedOnUserPatternsChanges]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (debouncedGetNewTestRef.current) {
      debouncedGetNewTestRef.current();
    }
  }, [
    excludedPatterns,
    includedPatterns,
    selectedTestLength,
    selectedWordList,
    desiredRandomness,
    letterLimit
  ]);

  //This is how I wrote it.
  // Change the typing test when the user makes a change to the patterns or basic test settings
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     console.log("Fetching new test based on changes to requested user patterns");
  //     const fetchData = async () => {
  //       try {
  //         await getNewTestBasedOnUserPatternsChanges();
  //       } catch (error) {
  //         console.error("Error fetching new test based on user patterns changes: ", error);
  //       }
  //     };
  //     fetchData();
  //   }
  // }, [excludedPatterns, includedPatterns, selectedTestLength, selectedWordList, desiredRandomness]);


  const contextValue = {
    selectedWordList, setSelectedWordList, selectedLanguage, setSelectedLanguage,
    selectedTestLength, setSelectedTestLength, includedPatterns, setIncludedPatterns,
    excludedPatterns, setExcludedPatterns,testAnalysisRef,getNewTestBasedOnUserPatternsChanges,
    typingTestText, setTypingTestText, getRandomTest, desiredRandomness, setDesiredRandomness,
    patternsAccordionExpanded, setPatternsAccordionExpanded, statisticsAccordionExpanded,
    setStatisticsAccordionExpanded, clearHistoryManager, algorithmFoundWords,
    setAlgorithmFoundWords, isFullyRandom, testStatus, setTestStatus, avatarIcon, setAvatarIcon,
    letterLimit, setLetterLimit, isLoading, setIsLoading, selectedStatisticsLanguage,
    setSelectedStatisticsLanguage, resetLesson, randomPhase, showNewRecord, setShowNewRecord
  };

  return (
    <SuperMainContentContext.Provider value={contextValue}>
      {children}
    </SuperMainContentContext.Provider>
  );
}